import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Container } from "reactstrap";
import { useParams } from "react-router-dom";
import { GlobalState } from "../../Data/Context";
import AirtimeIcon from "../../../src/Assets/airtime.svg";
import Cable from "../../../src/Assets/cable.svg";
import Autobuy from "../../Assets/autobuy.svg";
import Electricity from "../../Assets/electricity.svg";
import Education from "../../Assets/education.svg";
import Converter from "../../Assets/converter.svg";
import Data from "../../Assets/data.svg";
import UsersIcon from "../../Assets/users.svg";
import Verify from "../../Assets/verify.svg";
import { Icon } from "@iconify/react";

const colors = ["#f1c40f", "#fd7e14", "#0d6efd", "#d63384", "#198754"];
export let productArr = [
  {
    name: "Airtime",
    link: "/airtime",
    color: "linear-gradient(90deg, #DE0DE2 16.14%, #880EC2 101.45%)",
    textColor: "white",
    icon: "ic:outline-install-mobile",
    icon2: AirtimeIcon,
  },
  {
    name: "Airtime to Cash",
    link: "/converter",
    color: "linear-gradient(90deg, #DE0DE2 16.14%, #0E102D 101.45%)",
    textColor: "white",
    icon: "material-symbols:change-circle",
    icon2: Converter,
  },
  {
    name: "Data",
    link: "/data",
    color: "linear-gradient(90.18deg, #3199B7 -52.19%, #144468 81.92%)",
    textColor: "white",
    icon: "icon-park-twotone:data",
    icon2: Data,
  },

  {
    name: "Electricity bills",
    link: "/electricity-bills",
    color: "linear-gradient(90deg, #E43369 16.14%, #C20E19 101.45%)",
    textColor: "white",
    icon: "mage:light-bulb-electricity-fill",
    icon2: Electricity,
  },
  {
    name: "Cable Subscriptions",
    link: "/tv-subscriptions",
    color: "linear-gradient(90.18deg, #6CB731 -52.19%, #0F5A16 81.92%)",
    textColor: "white",
    icon: "eva:tv-fill",
    icon2: Cable,
  },
  {
    name: "Education",
    link: "/education",
    color: "linear-gradient(96.86deg, #F2E553 18.88%, #FF9900 125.77%)",
    icon: "mdi:cast-education",
    icon2: Education,
  },
  {
    name: "Auto Buy",
    link: "/auto-buy",
    color: "linear-gradient(90deg, #F45F83 16.14%, #9E1A2A 101.45%)",
    textColor: "white",
    icon: "game-icons:buy-card",
    icon2: Autobuy,
  },
  {
    name: "Verification",
    link: "/verification-type",
    color: "linear-gradient(96.86deg, #53F293 18.88%, #9EFF00 125.77%)",
    textColor: "black",
    icon: "uiw:verification",
    icon2: Verify,
  },
  {
    name: "CG Wallet",
    link: "/cgwallet",
    color: "linear-gradient(90.18deg, #3199B7 -52.19%, #144468 81.92%)",
    textColor: "white",
    icon: "tabler:wallet",
    icon2: UsersIcon,
  },
];

const Products = () => {
  let { setStateName, auth, getServicesHistory, getAllServicesHistory } =
    useContext(GlobalState);
  useEffect(() => {
    setStateName("all products");
    getServicesHistory("all");
    getServicesHistory("airtime");
    getServicesHistory("data");
    getServicesHistory("education");
    getServicesHistory("electricity");
    getServicesHistory("cables");
    getServicesHistory("biz");
    // getAllServicesHistory("all");
    getAllServicesHistory("airtime");
    getAllServicesHistory("data");
    getAllServicesHistory("education");
    getAllServicesHistory("electricity");
    getAllServicesHistory("cables");
    getAllServicesHistory("biz");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let [state, setState] = useState([]);

  useEffect(() => {
    setState(productArr);
  }, [auth?.user]);

  let params = useParams();

  return (
    <div className="bg-white aboutScreen">
      <Container className="tw-pt-16">
        <div className="tw-grid lg:tw-w-3/4 tw-w-11/12 tw-gap-10  tw-mx-auto md:tw-grid-cols-2 lg:tw-grid-cols-3">
          {state?.map((item, i) => (
            <div
              className="tw-flex tw-justify-between tw-px-6 tw-h-24 tw-shadow-md tw-shadow-blue-50 tw-items-center"
              key={i}
            >
              <div>
                <Icon
                  color={colors[i % colors.length]}
                  style={{
                    fontSize: "36px",
                    // color: colors[i % colors.length],
                  }}
                  icon={item?.icon}
                />
                {/* <img src={item.icon2} alt="" className="tw-h-16" /> */}
              </div>
              <Link
                to={
                  item?.link?.includes("converter")
                    ? item?.link
                    : `/${params?.page}${item?.link}`
                }
                className="d-flex align-items-center justify-content-center text2 myCursor text-decoration-none h-100 eachProduct fontReduceBig textTrunc p-2 p-md-0 py-3 py-md-0 h-100"
                // style={{
                //   background: item?.color,
                //   borderRadius: "30px",
                //   color: item?.textColor ? item?.textColor : "#000",
                // }}
              >
                <span className="tw-text-xl tw-text-[#1b1b1b] tw-font-medium text-capitalize">
                  {item?.name}
                </span>
              </Link>
            </div>
          ))}
        </div>
      </Container>
    </div>
  );
};

export default Products;
